import React from "react";
import Layout from "@/components/layout";
import CallToActionOne from "@/components/call-to-action-one";
import Footer from "@/components/footer";
import BlogHome from "@/components/blog-home";
import ParallaxOne from "@/components/parallax-1";
import ClientCarouselOne from "@/components/client-carousel-one";
import TeamCarousel from "@/components/team-carousel";
import FunfactOne from "@/components/funfact-one";
import TrustedClient from "@/components/trusted-client";
// import PortfolioHome from "@/components/portfolio-home";
import ServiceTwo from "@/components/service-two";
import AboutTwo from "@/components/about-two";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
// import SliderOne from "@/components/slider-one";
import SEO from "@/components/seo";

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Solxk - Home"
          description="Welcome to Solxk, your trusted partner in software development and cybersecurity solutions. Discover our wide range of services designed to enhance your digital presence."
        />
        <Layout PageTitle="Home">
          <HeaderOne />
          {/* <SliderOne /> */}
          <ServiceTwo />
          <AboutTwo />
          {/* <PortfolioHome /> */}
          <FunfactOne />
          <TrustedClient />
          <TeamCarousel />
          <ClientCarouselOne />
          <ParallaxOne />
          {/* <BlogHome /> */}
          {/* <CallToActionOne extraClassName="ready" /> */}
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
